<template>
    <form class="form" @submit.prevent="recoveryPassword">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">Email</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/mdi_email-outline.svg" class="form__input-img-icon" />
                    <input type="text" placeholder="Enter your email" class="form__input form--input-padding-left"
                        v-model="customerEmail.email">
                </div>

                <div class="form__input-error" v-if="errors.fields.email">
                    {{ errors.fields.email[0] }}
                </div>
            </div>

            <ButtonTextLoader button-class="button button--primary auth__right-button-resize"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />

            <button class="button button--primary auth__right-button-resize" v-if="!buttonLoader">
                Recover Password
                <img src="@/assets/icons/ph_arrow-right.svg" class="button__icon" />
            </button>
        </div>
    </form>
</template>

<script setup>
import { forgotPasswordServices } from '../Services/ForgotPasswordServices.js'
import { sweetAlerts } from '@/helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import ButtonTextLoader from '@/common/ButtonTextLoader.vue'

const { sendEmailService, errors } = forgotPasswordServices()
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const customerEmail = ref({ email: '' })
const buttonLoader = ref(false)

async function recoveryPassword() {
    buttonLoader.value = true
    const response = await sendEmailService(customerEmail.value)

    if (response.hasErrors) {
        buttonLoader.value = false
        return console.error(`Error in recovery password service: ${response.message}`)
    }

    router.push('/login')

    return sweetSuccess(response.data.message)
}
</script>