import { httpRequests } from '../../../helpers/HttpRequests.js'

export function forgotPasswordServices() {
    const { postRequest, httpErrors } = httpRequests();
    const errors = httpErrors

    function sendEmailService(email) {
        return postRequest('/reset_password/send_email', email);
    }

    return { sendEmailService, errors }
}
